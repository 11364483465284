<mat-card-header class="header" style="display: flex;flex-direction: row; justify-content: space-between;">
  <mat-card-title>Detalles de la reunión</mat-card-title>
  <div class="buttons" style="display: flex;flex-direction: row;">
    <button mat-flat-button class="btn-mod" style="margin-bottom: 1%;margin-right: 5%;" (click)="this.onModificar()"
      [disabled]="this.started == true || this.enabled == false">Modificar reunión</button>

    <button mat-flat-button color="warn" style="margin-bottom: 1%;" (click)="this.onEliminar()"
      [disabled]="this.started ==true || this.enabled == false">Eliminar reunión</button>

  </div>
</mat-card-header>
<mat-divider></mat-divider>
<mat-card-content style="margin-top: 5%;">
  <!-- put the form here -->
  <form style="display: flex;flex-direction: column;justify-content: space-between;">
    <mat-form-field>
      <mat-label>Título reunión</mat-label>
      <mat-error *ngIf="tituloFormControl.hasError('required')">El titulo es requerido</mat-error>
      <input matInput placeholder="Título" name="Título" [formControl]="tituloFormControl"
        [readonly]="this.started == true || this.enabled == false">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Descripción</mat-label>
      <textarea matInput [formControl]="descripcionFormControl"
        [readonly]="this.started == true || this.enabled == false"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nombre del sindicato</mat-label>
      <input matInput placeholder="Título" name="Título" [readonly]="true" [value]="this.nombreSindicato">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nombre/correo admin sindicato</mat-label>
      <input matInput placeholder="Título" name="Título" [readonly]="true" [value]="this.adminSindicato">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fecha</mat-label>
      <mat-error>La fecha es requerida</mat-error>
      <input matInput [matDatepicker]="myPicker" readonly placeholder="Fecha" [formControl]="fechaFormControl"
        [disabled]="this.started == true || this.enabled == false">
      <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
      <mat-datepicker #myPicker></mat-datepicker>
    </mat-form-field>
    <mat-error *ngIf="this.fechaCorrecta == false">La fecha ingresada ya ha pasado, ingresar fecha a partir de hoy
    </mat-error>
    <div class="fechas" style="display: flex;flex-direction: row;justify-content: space-evenly;">
      <mat-label>Hora inicio</mat-label>
      <div class="timepicker-overlay" style="display: flex;flex-direction: row;">

        <input [ngxTimepicker]="horaInicio" [disableClick]="true" [format]=24 readonly required
          [formControl]="horaInicioFormControl" [disabled]="this.started == true || this.enabled == false">
        <ngx-material-timepicker-toggle [for]="horaInicio"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #horaInicio></ngx-material-timepicker>
      </div>
      <mat-label>Hora término</mat-label>

      <div class="timepicker-overlay" style="display: flex;flex-direction: row;">

        <input [ngxTimepicker]="horaTermino" [disableClick]="true" [format]=24 readonly required
          [formControl]="horaTerminoFormControl" [disabled]="this.started == true || this.enabled == false">
        <ngx-material-timepicker-toggle [for]="horaTermino"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #horaTermino></ngx-material-timepicker>
      </div>

    </div>
    <mat-error *ngIf="this.horaTerminoVacia == true || this.horaInicioVacia == true"
      style="text-align: center;margin-top: 10px;">Las horas no pueden estar vacías!</mat-error>
    <mat-error *ngIf="this.horaCorrecta == false && (this.horaInicioVacia == false && this.horaTerminoVacia == false)"
      style="text-align: center;margin-top: 10px;">La hora de inicio no puede ser mayor a la hora de término</mat-error>

  </form>
</mat-card-content>
<div *ngIf="this.enabled == true">
  <mat-card-actions style="text-align: center;" *ngIf="this.started == false">
    <!-- put buttons here -->
    <button mat-flat-button style="margin-bottom: 1%;margin-right: 5%;background-color: #BF1F13;color: white;"
      (click)="this.onIniciarReunion()">Iniciar reunión</button>
    <button mat-flat-button style="margin-bottom: 1%;margin-right: 5%;background-color:#730800;color: white;"
      (click)="this.adjuntarContrato()">Adjuntar contrato actual</button>
  </mat-card-actions>
</div>