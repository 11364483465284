<mat-tab-group>
    <mat-tab label="Historial de reuniones">
        <div *ngIf="this.tieneReuniones == false" style="text-align: center;" > 
            <h1>No hay reuniones recientes</h1>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading == true"></mat-progress-bar>
        <div *ngIf="this.tieneReuniones== true">
        <h1 style="text-align: center;">Historial de reuniones</h1>
        <table mat-table #mytable [dataSource]="this.dataSource" class="mat-elevation-z8" style="width: 100%">
            <ng-container matColumnDef="Titulo">
                <th class="w-a" mat-header-cell *matHeaderCellDef>Título</th>
                <td mat-cell *matCellDef="let element">
                    {{element.titulo}}
                    
                </td>
        
            </ng-container>
            <ng-container matColumnDef="Fecha">
                <th class="w-a" mat-header-cell *matHeaderCellDef>Fecha</th>
                <td mat-cell *matCellDef="let element">
                  {{element.fecha}}
                </td>
        
            </ng-container>
        
            
            <ng-container matColumnDef="Detalles">
                <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" style="display: flex;flex-direction: row;justify-content: space-between;">
                    <button class="btn-detalles" mat-flat-button color="primary" (click)="verDetalle(element)">Ver detalle</button>
                    <!--<mat-icon style="color: gray;" (click)="verContrato(element)" > insert_drive_file</mat-icon>-->
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        </div>

    </mat-tab>
    <mat-tab label="Historial de cambios">
        <mat-list>
            <mat-list-item *ngFor="let cambio of historialDocList">
              
              <h3 matLine> El usuario {{cambio.nombre}} ha realizado un cambio en el documento de contrato colectivo en la fecha {{cambio.fecha}}  </h3>
              
              <button mat-button (click)="verContrato(cambio)" class="btn-doc">Ver documento</button>
            </mat-list-item>
            
          </mat-list>
<!------
        <mat-select name="historialDocList">
            <mat-option  *ngFor="let cambio of historialDocList" [value]="cambio.idAdmin">
                El usuario {{cambio.nombre}} ha realizado un cambio en el documento de contrato colectivo 
            </mat-option>
          </mat-select>-->

    </mat-tab>
</mat-tab-group>

