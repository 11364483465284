

<body class ="body">
  <app-spinner *ngIf="isLoading"></app-spinner>
        <mat-card class="my-5" *ngIf="!isLoading">

          <mat-card-content  class="cardContent" >
            <div class="logo-container">
              <img id="logo" src="../../../assets/logo.png">
            </div>
            <form class="register" >
              <mat-form-field class="form-register">
                <mat-label>Nombre completo</mat-label>
                <input matInput  [formControl]="nameFormControl" placeholder="Nombre completo" name="nombre" required maxlength="100">
              </mat-form-field>
              <mat-form-field class="form-register">
                <mat-label>Email</mat-label>
                <input matInput  [formControl]="emailFormControl" placeholder="Email" name="email" required [errorStateMatcher] ="matcher" >

              </mat-form-field>
              <mat-form-field class="form-register">
                <mat-label>Contraseña</mat-label>
                <input matInput  [formControl]="passwordFormControl" placeholder="Contraseña (mínimo 7 carácteres)" [type]="hide ? 'password' : 'text'" name="contraseña" type="password" required minlength="7">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
              
              <mat-form-field>
                <mat-label >Seleccionar organización</mat-label>
                <mat-select class="select" [(value)]="org">
                  <mat-option value="Sindicato">Sindicato</mat-option>
    <mat-option value="Fundación">Fundación</mat-option>
                </mat-select>
                
              </mat-form-field>
            </form>
            <mat-card-actions class="botonSection">
              <button [disabled]=" !emailFormControl.valid || !passwordFormControl.valid || !nameFormControl.valid" mat-raised-button class="boton"  color="primary" type="submit" (click) ="onRegister()">Registrarse</button>
              
            </mat-card-actions>
          </mat-card-content>
        </mat-card>
</body>
