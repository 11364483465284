<h1 *ngIf="this.miembrosExists == true" style="text-align: center;">Miembros del sindicato</h1>
<mat-progress-bar mode="indeterminate" *ngIf="this.isLoading == true"></mat-progress-bar>
<table class="table-responsive" mat-table #mytable  [dataSource]="this.dataSource" >
    <ng-container matColumnDef="nombre">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Nombre </th>
        <td mat-cell *matCellDef="let element">
            {{element.nombre}}
            
        </td>

    </ng-container>
    <ng-container matColumnDef="correo">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Correo</th>
        <td mat-cell *matCellDef="let element">
          {{element.correo}}
        </td>

    </ng-container>
  
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
