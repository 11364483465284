<mat-card-title style="text-align: center;"> Crear Sindicato</mat-card-title>

<mat-card>

  <mat-card-content class="cardContent">

    <form class="register">
      <mat-form-field class="form-register">
        <mat-label>Nombre</mat-label>
        <input matInput [formControl]="nameFormControl" placeholder="Nombre" name="nombre" required maxlength="100">
      </mat-form-field>
      <mat-form-field class="form-register">
        <mat-label>Seleccionar correo de administrador para el sindicato</mat-label>
        <mat-select [(ngModel)]="selectedAdmin" name="listaCorreosValidos">
          <mat-option (click)="selectAdmin()" *ngFor="let admin of listaCorreosValidos" [value]="admin.correo">
            {{admin.correo}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <!--<button  mat-raised-button class="boton"  color="primary" type="submit" (click) ="asignarAbogado()">Asignar abogado</button>-->
    </form>
    <mat-card-actions class="botonSection">
      <button [disabled]="this.adminSelected == false  ||  !nameFormControl.valid" mat-raised-button class="boton"
        color="primary" type="submit" (click)="onAddSindicato()">Aceptar</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>