import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { RecuperarPassComponent } from './auth/recuperar-pass/recuperar-pass.component';
import { AuthService } from './services/auth.service';
import { InicioComponent } from './inicio/inicio.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { EstadoFinancieroComponent } from './estado-financiero/estado-financiero.component';
import { HistorialComponent } from './historial/historial.component';
import { ContratoColectivoComponent } from './contrato-colectivo/contrato-colectivo.component';
import { InnominadaComponent } from './innominada/innominada.component';
import { ReunionComponent } from './reunion/reunion.component';
import { CrearSindicatoComponent } from './crear-sindicato/crear-sindicato.component';
import { SindicatoComponent } from './sindicato/sindicato.component';
import { ActivarCuentaComponent } from './activar-cuenta/activar-cuenta.component';
import { AgregarUsuarioSindicatoComponent } from './agregar-usuario-sindicato/agregar-usuario-sindicato.component';
import { FundacionComponent } from './fundacion/fundacion.component';
import { CrearFundacionComponent } from './crear-fundacion/crear-fundacion.component';
import { SindicatosFundacionComponent } from './sindicatos-fundacion/sindicatos-fundacion.component';
import { ModalDetalleSindicatoFundacionComponent } from './modal-detalle-sindicato-fundacion/modal-detalle-sindicato-fundacion.component';
import { EstadoFinancieroFundacionComponent } from './estado-financiero-fundacion/estado-financiero-fundacion.component';
import { InominadaFundacionComponent } from './inominada-fundacion/inominada-fundacion.component';
import { ReunionFundacionComponent } from './reunion-fundacion/reunion-fundacion.component';
import { HistorialFundacionComponent } from './historial-fundacion/historial-fundacion.component';
import { ContratoFundacionComponent } from './contrato-fundacion/contrato-fundacion.component';
import { EventosComponent } from './eventos/eventos.component';
import { EventosFundacionComponent } from './eventos-fundacion/eventos-fundacion.component';
import { PropuestasComponent } from './propuestas/propuestas.component';
import { PropuestasFundacionComponent } from './propuestas-fundacion/propuestas-fundacion.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  {
    path: 'home',
    canActivate: [AuthService],
    component: HomeComponent,
    children: [{
      path: '',
      component: EstadoFinancieroComponent
    },
    {
      path: 'estadoFinanciero',
      component: EstadoFinancieroComponent
    },
    {
      path: 'reunion',
      component: ReunionComponent
    },
    {
      path: 'innominada',
      component: InnominadaComponent
    },
    {
      path: 'historial',
      component: HistorialComponent
    },
    {
      path: 'contrato',
      component: ContratoColectivoComponent
    },

    {
      path: 'sindicato',
      component: SindicatoComponent
    }
      ,
    {
      path: 'crearSindicato',
      component: CrearSindicatoComponent
    },
    {
      path: 'fundacion',
      component: FundacionComponent
    },
    {
      path: 'crearFundacion',
      component: CrearFundacionComponent
    },
    {
      path: 'sindicatos-fundacion',
      component: SindicatosFundacionComponent
    },
    {
      path: 'estado-financiero-fundacion',
      component: EstadoFinancieroFundacionComponent
    },
    {
      path: 'inominada-fundacion',
      component: InominadaFundacionComponent
    },
    {
      path: 'reunion-fundacion',
      component: ReunionFundacionComponent
    },
    {
      path: 'historial-fundacion',
      component: HistorialFundacionComponent
    },
    { path: 'contrato-fundacion', component: ContratoFundacionComponent },
    { path: 'eventos', component: EventosComponent },
    {path: 'eventos-fundacion',component:EventosFundacionComponent},
    {path: 'propuestas',component:PropuestasComponent},
    {path: 'propuestas-fundacion', component:PropuestasFundacionComponent}

    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: RecuperarPassComponent },
  { path: 'activate', component: ActivarCuentaComponent },
  { path: 'registerUserSindicato', component: AgregarUsuarioSindicatoComponent },
  { path: 'detalleSindicatoFundacion', component: ModalDetalleSindicatoFundacionComponent },
  { path: 'crearSindicatoFundacion', component: CrearSindicatoComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
