
<body class="body">

  <mat-card class="my-5">
    <mat-card-content class="cardContent" >
      <div class="logo-container">
        <img id="logo" src="../../../assets/logo.png">
      </div>
      <form class="login">
        <div class="row" *ngIf="authError">
          <mat-error>
            La combinación de email y contraseña es incorrecta
          </mat-error>

        </div>
        <mat-form-field class="form-login">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailFormControl" placeholder="Email" name="email" required
            [errorStateMatcher]="matcher">
        </mat-form-field>

        <mat-form-field class="form-login">
          <mat-label>Contraseña</mat-label>
          <input matInput [formControl]="passwordFormControl" placeholder="Contraseña" name="contraseña" [type]="hide ? 'password' : 'text'"
            required minlength="7">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <div class="resetForm">
          <p> <a [routerLink]="['/reset']">¿Olvidó su contraseña?</a></p>
          <div class="registerForm">
            <p> <a [routerLink]="['/register']">Registrar administrador sindicato / fundación</a></p>
          </div>

          <div class="registerForm">
            <p> <a [routerLink]="['/activate']">Activar cuenta miembro sindicato / fundación</a></p>
          </div>
        </div>
      </form>

      <mat-card-actions class="botonSection">
        <button mat-raised-button class="boton" color="primary" type="submit" (keydown.enter)="onLogin()" (click)="onLogin()">Iniciar
          Sesión</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>




</body>
