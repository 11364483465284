

<body class ="body">
    
          <mat-card class="my-5">
  
            <mat-card-content  class="cardContent" >
              <div class="logo-container">
                <img id="logo" src="../../../assets/logo.png">
              </div>
              <form class="register" >
                <mat-form-field class="form-register">
                  <mat-label>Email</mat-label>
                  <input matInput  [formControl]="emailFormControl" placeholder="Email" name="email" required [errorStateMatcher] ="matcher" >
  
                </mat-form-field>
                <mat-form-field class="form-register">
                  <mat-label>Contraseña</mat-label>
                  <input matInput  [formControl]="passwordFormControl" placeholder="Contraseña (mínimo 7 carácteres)" [type]="hide ? 'password' : 'text'" name="contraseña" type="password" required minlength="7">
                  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
              </form>
              <mat-card-actions class="botonSection">
                <button [disabled]=" !emailFormControl.valid || !passwordFormControl.valid" mat-raised-button class="boton"  color="primary" type="submit" (click) ="onActivate()">Activar Cuenta</button>
              </mat-card-actions>
            </mat-card-content>
          </mat-card>
