<div *ngIf="this.haveFundacion == true">

    <mat-progress-bar mode="indeterminate" *ngIf="this.isLoading == true"></mat-progress-bar>
    <h1 *ngIf="this.sindicatosExists == true" style="text-align: center;">Sindicatos asociados</h1>
    <!--<mat-progress-bar mode="indeterminate" *ngIf="this.isLoading == true"></mat-progress-bar>-->

    <table mat-table #mytable *ngIf="this.sindicatosExists == true" [dataSource]="this.dataSource"
        class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="Nombre">
            <th class="w-a" mat-header-cell *matHeaderCellDef>Nombre del sindicato</th>
            <td mat-cell *matCellDef="let element">
                {{element.nombre}}

            </td>

        </ng-container>
        <ng-container matColumnDef="Admin">
            <th class="w-a" mat-header-cell *matHeaderCellDef>Admin</th>
            <td mat-cell *matCellDef="let element">
                {{element.admin}}
            </td>

        </ng-container>
        <ng-container matColumnDef="Correo_admin">
            <th class="w-a" mat-header-cell *matHeaderCellDef>Correo admin</th>
            <td mat-cell *matCellDef="let element">
                {{element.correoAdmin}}
            </td>

        </ng-container>
        <ng-container matColumnDef="Miembros">
            <th class="w-a" mat-header-cell *matHeaderCellDef>Cantidad de miembros</th>
            <td mat-cell *matCellDef="let element">
                {{element.cantidadMiembros}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Detalles">
            <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"
                style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 2%;">
                <button class="add-user" mat-flat-button color="primary" (click)="verDetalle(element)">Ver
                    miembros</button>
            </td>
        </ng-container>

        <ng-container matColumnDef="Abogado">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" style="margin-top:2%;">
                <div (click)="addLawyer(element)" *ngIf="this.checkEnabled(element) == true" matTooltip="asociar abogados">
                    <img class="addLawyer" src="../../../assets/addLawyer.svg" style="width: 50px;" alt="grafico"
                        >
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="verAbogado">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td class="verAbogado" mat-cell *matCellDef="let element" style="margin-top:2%;">
                <div (click)="verAbogados(element)" *ngIf="this.checkEnabled(element) == true" matTooltip="abogados asociados">
                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-eye-fill" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path fill-rule="evenodd"
                            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="deshabilitar">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td class="verAbogado" mat-cell *matCellDef="let element" style="margin-top:2%;"
                (click)="deshabilitarSindicato(element)">
                <mat-icon  matTooltip="deshabilitar sindicato" *ngIf="this.checkEnabled(element) == true">block</mat-icon>
                <mat-icon *ngIf="this.checkEnabled(element) == false" matTooltip="habilitar sindicato" >check</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <h1 *ngIf="this.sindicatosExists == false">No tiene sindicatos asociados a esta fundación</h1>


    <div style="display:flex ;flex-direction: row;justify-content: center;margin-top: 2%;margin-bottom: 2%;">
        <button class="add-user" mat-flat-button color="primary" (click)="crearSindicato()">Crear Sindicato</button>

    </div>
</div>
<div *ngIf="haveFundacion == false">
    <h1>Usted no tiene asociada una fundación a su cuenta, por favor cree una fundación para comenzar</h1>
</div>