
  <mat-card-header class="header">
      <mat-card-title  >Agendar una reunión</mat-card-title>
  </mat-card-header>
<mat-divider></mat-divider>
      <mat-card-content style="margin-top: 5%;">
      <!-- put the form here -->
      <form style="display: flex;flex-direction: column;justify-content: space-between;">
        <mat-form-field>
          <mat-label>Título reunión</mat-label>
          <mat-error *ngIf="tituloFormControl.hasError('required')">Campo requerido</mat-error>
          <input  matInput  placeholder="Título"  name="Título"  required  [formControl]="tituloFormControl">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Descripción</mat-label>
        <textarea  matInput [formControl]="descripcionFormControl"></textarea>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Seleccionar abogado</mat-label>
      <mat-select  [(ngModel)]="selectedAbogado" name="abogadoList">
        <mat-option (click)="selectAbogado()" *ngFor="let abogado of abogadoList" [value]="abogado.uid">
          {{abogado.nombre}}, correo: {{abogado.correo}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fecha</mat-label>
      <mat-error >La fecha es requerida</mat-error>

      <input   matInput [matDatepicker]="myPicker" readonly placeholder="Fecha" [formControl]="fechaFormControl" required>
      <mat-datepicker-toggle  matSuffix [for]="myPicker"></mat-datepicker-toggle>
      <mat-datepicker #myPicker></mat-datepicker>
  </mat-form-field>
  <mat-error *ngIf="this.fechaCorrecta == false">La fecha/hora ingresada ya ha pasado</mat-error>
  <div class="fechas">
  <mat-label>Hora inicio</mat-label>

  <div class="timepicker-overlay" style="display: flex;flex-direction: row;">

    <input [ngxTimepicker]="horaInicio" [disableClick]="true" [format]=24 readonly required [formControl]="horaInicioFormControl">
    <ngx-material-timepicker-toggle [for]="horaInicio"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #horaInicio  ></ngx-material-timepicker>
</div>
<mat-label>Hora término</mat-label>

<div class="timepicker-overlay" style="display: flex;flex-direction: row;">

  <input [ngxTimepicker]="horaTermino" [disableClick]="true" [format]=24 readonly required [formControl]="horaTerminoFormControl">
  <ngx-material-timepicker-toggle [for]="horaTermino"></ngx-material-timepicker-toggle>
  <ngx-material-timepicker #horaTermino></ngx-material-timepicker>
</div>

</div>
<mat-error *ngIf="this.horaTerminoVacia == true || this.horaInicioVacia == true" style="text-align: center;margin-top: 10px;" >Las horas no pueden estar vacías!</mat-error>
<mat-error *ngIf="this.horaCorrecta == false && (this.horaInicioVacia == false && this.horaTerminoVacia == false)" style="text-align: center;margin-top: 10px;">La hora de inicio no puede ser mayor a la hora de término</mat-error>
<mat-card-actions class="modal-buttons">
<button class="btn-agendar" (click)="onAgendar()" mat-raised-button color="primary">Agendar</button>
<button (click)="onCerrar()" mat-raised-button color="warn">Cancelar</button>
</mat-card-actions>
      </form>
      </mat-card-content>
      <mat-card-actions>
      <!-- put buttons here -->
      </mat-card-actions>

