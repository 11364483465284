<div class="demo-app">

    <div class='demo-app-main'>
    <div>
      <button class="btn-agendar" mat-flat-button color="primary" (click)="this.openDialog()" style="margin-bottom: 1%;">Agendar reunión</button>
    
    </div>
    
        <full-calendar
        [options]='options'
    
      ></full-calendar>
    
    
    
    </div>
    