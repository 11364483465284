<div style="text-align: center;">
    <h1>Agregar fundación</h1>
</div>
<div style="display: flex;flex-direction: row;justify-content: flex-end;">
    <button  class="btn" mat-flat-button  [disabled]="!fundacion.valid"  (click)="onCrearFundacion()">Crear
        Fundación</button>
</div>
<form style="display: flex;flex-direction: column;justify-content: space-between;" #fundacion="ngForm"
    [formGroup]="group">
    <mat-form-field>
        <mat-label>Nombre fundación</mat-label>

        <input matInput placeholder="Nombre" name="Nombre" required formControlName="nameControl">
    </mat-form-field>

</form>
<h3>Agregar abogados a fundación</h3>
<table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" style="width: 100%">
    <ng-container matColumnDef="Nombre">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field >
                <input matInput  #nombre="ngModel" [value]="element.nombre" [(ngModel)]="element.nombre" required>
                <mat-error *ngIf="nombre?.invalid">
                    <div *ngIf="nombre.errors.required">El campo no puede estar vacío</div>
                </mat-error>
            </mat-form-field>
            
        </td>

    </ng-container>
    <ng-container matColumnDef="Correo">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Correo</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field >
                <input matInput #email="ngModel"  [value]="element.correo"   [(ngModel)]="element.correo" pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"  required>
                <mat-error *ngIf="email?.invalid">
                    <div *ngIf="email.errors.required">El campo no puede estar vacío</div>
                    <div *ngIf="email.errors.pattern">Formato de correo incorrecto</div>
                </mat-error>
            </mat-form-field>
        </td>

    </ng-container>

    <ng-container matColumnDef="Contraseña">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Contraseña</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field>
                <input matInput #password="ngModel"  [type]="hide ? 'password' : 'text'" 
                    [value]="element.pass" [(ngModel)]="element.pass" required pattern="^.{7,}$">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="password?.invalid">
                    <div *ngIf="password.errors.required">El campo no puede estar vacío</div>
                    <div *ngIf="password.errors.pattern">Largo mínimo 7 caracteres</div>
                </mat-error>
                
            </mat-form-field>
        </td>

    </ng-container>


    <ng-container matColumnDef="columndelete">
        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon (click)="delete(element)">delete</mat-icon>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div style="display:flex ;flex-direction: row;justify-content: center;margin-top: 2%;margin-bottom: 2%;">
    <button class="btn" mat-flat-button  (click)="onAddUser()">+</button>

</div>