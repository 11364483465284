<div style="display: flex;flex-direction: column;">

    <h1 mat-dialog-title>
        Detalles de la reunión
    </h1>
    <div mat-dialog-content>
        <mat-form-field style="width:200px !important;">
            <input placeholder="Titulo" matInput [(ngModel)]="this.titulo" readonly>
        </mat-form-field><br>
        <mat-form-field  *ngIf="this.descripcion != '' " style="width:200px !important;">
            <input placeholder="Descripción" matInput [(ngModel)]="this.descripcion" readonly>
        </mat-form-field>

        <mat-form-field style="width:200px !important;">
            <input placeholder="Nombre abogado" matInput [(ngModel)]="this.nombreAbogado" readonly>
        </mat-form-field>
        <mat-form-field style="width:200px !important;"> 
            <input placeholder="Correo abogado" matInput [(ngModel)]="this.correoAbogado" readonly>
            </mat-form-field>
    </div>
    <mat-form-field style="width:200px !important;">
        <input placeholder="Fecha" matInput [(ngModel)]="this.fecha" readonly>
    </mat-form-field>

    <mat-card-actions style="text-align: center;">
        <button [disabled]="!this.haveContract" class="btn-ver-doc" mat-flat-button color="primary" (click)="verContrato()">Ver contrato</button>
    </mat-card-actions>
    

</div>