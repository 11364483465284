<mat-toolbar class="body">
  <mat-toolbar-row>
    <button mat-icon-button (click)="onToggle()">
        <mat-icon>menu</mat-icon>
    </button>

    
    <div  class="user-menu" fxFlex fxLayout="row" fxLayoutAlign="flex-end" >
      <ul class ="user-menu" fxLayout="row" fxLayoutGap="20px" >

        <li>
          <button mat-button [matMenuTriggerFor]="menu" >
            <mat-icon class="user-icon" style="color:white">person outline</mat-icon>

          </button>
          <mat-menu #menu="matMenu" >
            <button *ngIf ="this.isAdmin == true && this.organization == 'Sindicato' " mat-menu-item  routerLink="sindicato">
              <mat-icon>supervised_user_circle</mat-icon>
                Sindicato
            </button>
            <button *ngIf ="this.isAdmin == true && this.organization == 'Fundación' " mat-menu-item  routerLink="fundacion">
              <mat-icon>supervised_user_circle</mat-icon>
                Fundación
            </button>
              <button mat-menu-item (click)="onLogout()" routerLink="/login">
                  <mat-icon>exit_to_app</mat-icon>
                    Cerrar Sesión
                </button>
                
          </mat-menu>
        </li>

      </ul>
    </div>
</mat-toolbar-row>
</mat-toolbar>
