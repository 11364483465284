<h1 *ngIf="this.abogadosExists == true" style="text-align: center;">Abogados asociados</h1>
<mat-progress-bar mode="indeterminate" *ngIf="this.isLoading == true"></mat-progress-bar>
<h2 *ngIf="this.abogadosExists == false">No hay abogados asociados a este sindicato</h2>
<table class="table-responsive" mat-table #mytable  [dataSource]="this.dataSource" *ngIf="this.abogadosExists == true" >
    <ng-container matColumnDef="nombre">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Nombre </th>
        <td mat-cell *matCellDef="let element">
            {{element.nombre}}
            
        </td>

    </ng-container>
    <ng-container matColumnDef="correo">
        <th class="w-a" mat-header-cell *matHeaderCellDef>Correo</th>
        <td mat-cell *matCellDef="let element">
          {{element.correo}}
        </td>

    </ng-container>
    <ng-container matColumnDef="eliminar">
        <th style="width:15%;" mat-header-cell *matHeaderCellDef> </th>
        <td class="eliminar" mat-cell *matCellDef="let element">
            <mat-icon (click)="delete(element)">delete</mat-icon>
        </td>
    </ng-container>
    
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
